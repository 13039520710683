<template>
  <div class="dropdown my-profile navbar__my-profile" @focusout="closeProfileDropdown">
    <button class="btn btn-link dropdown-toggle" type="button" @click="login">
      <span class="my-profile__avatar">
        <img class="my-profile__avatar-img" src="@/assets/img/avatar.svg">
        <button class="navbar__hamburger">
          <span class="navbar__hamburger-line"></span>
          <span class="navbar__hamburger-line"></span>
          <span class="navbar__hamburger-line"></span>
        </button>
      </span>
      <span class="my-profile__text" v-if="auth">{{ profile.email }}</span>
    </button>
    <div class="dropdown-menu dropdown-menu-end my-profile__dropdown-menu"
         style="left: -130px;"
         :class="{show:show}" v-if="auth">
      <div class="my-profile__head">
        <div class="my-profile__this">{{ $t('profiletitle') }}</div>
        <div class="my-profile__name d-none">{{ profile.fio }}</div>
        <div v-if="profile.verified" class="badge bg-success my-profile__status d-none">
          {{ $t('verified') }}
        </div>
        <div v-else class="badge bg-danger my-profile__status d-none">
          {{ $t('notverified') }}
        </div>
      </div>
      <div class="my-profile__body" v-show="false"></div>
      <div class="my-profile__foot">
        <div class="my-profile__menu">
          <router-link to="/profile" class="my-profile__link" @click.native="closeMy">
            <span class="my-profile__link-pict"><svg class="my-profile__link-icon">
              <use xlink:href="#i-calling"></use></svg>
            </span>
            <span class="my-profile__link-text">{{ $t('dashboardtitle') }}</span>
          </router-link>
         <router-link to="/faq" class="my-profile__link" @click.native="closeMy">
            <span class="my-profile__link-pict">
              <svg class="my-profile__link-icon"><use xlink:href="#i-lock"></use></svg>
            </span>
            <span class="my-profile__link-text">{{ $t('supporttitle') }}</span>
          </router-link>
          <a href="javascript:void(0)" class="my-profile__link hand" @click="logout">
            <span class="my-profile__link-pict">
              <svg class="my-profile__link-icon"><use xlink:href="#i-logout"></use></svg>
            </span>
            <span class="my-profile__link-text">{{ $t('logout') }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {commitToggleModal} from '@/store/main/mutations';
import {readUser, readUserFp} from '@/store/main/getters';
import {dispatchGetUser} from '@/store/main/actions';

export default {
  name: 'ProfileMenu',
  data() { return {
    show: false,
    auth: false,
    base: 'USDT',
    profile: {
      fio: '',
      email: '',
      verified: false,
    },
  }},
  computed: {
    user() {
      return readUser(this.$store);
    },
  },
  methods: {
    logout() {
      commitToggleModal(this.$store, 'logout');
    },
    login() {
      if (this.auth) {
        this.show = !this.show;
        if (!this.profile.fio.length) this.loadProfile();
      } else {
        commitToggleModal(this.$store, 'login');
      }
    },
    loadProfile(){
      axios.post(this.$store._modules.root.state.main.API_URL+'/user',
          {method: 'loadProfile',
            data: { fp: readUserFp(this.$store), uid: this.user.uid}},{headers: {'Authorization':'Bearer ' + this.user.hash}}).then(r => {
        console.log('loadProfile', r.data);
        if (typeof(r.data.data.profile)=='object') this.profile = r.data.data.profile;
      });
    },
    closeProfileDropdown(e) {
      //console.log('close out');
      console.log('close out', e);
      if(e.relatedTarget == null || !e.relatedTarget.className.includes('my-profile__link'))  this.show = false;
    },
    closeMy() {
      this.show = false;
    }
  },
  created() {
    dispatchGetUser(this.$store);
    this.auth = !!this.user.hash;
    if (this.auth) {
      if (!this.profile.fio.length) this.loadProfile();
    }
  },
}
</script>

<style>
.my-profile__avatar-img {
  display: block;
}
.navbar__hamburger {
  display: none;
}
.my-profile .dropdown-toggle::after {
  display: block;
}
@media (max-width: 1024px) {
  .my-profile__avatar-img {
    display: none;
  }
  .navbar__hamburger {
    display: block;
  }
  .my-profile .dropdown-toggle::after {
    display: none;
  }
  .navbar__right .navbar__toggle, .navbar .dropdown.navbar__my-profile .my-profile__text {
    display: none;
  }
}
</style>
