import axios from 'axios';
import store from './store';
import {commitSetUser} from './store/main/mutations';

export async function api(page: string, id: string, param: object = {}) {
  const url: string = store.state.main.API_URL+'/' + page;

  const data: object = {
    method: id,
    data: {
      ...param,
      fp: store.getters.user_fp,
      uid: store.getters.user.uid,
      lang: localStorage.lang
    },
  };

  const response = await fetch( url, {
    method: 'POST',
    headers: { Authorization: 'Bearer ' + store.getters.user.hash },
    body: JSON.stringify(data),
  });

  const result = await response.json();

  if(result.code === 0 && result.data.error === 'accessDenied') {
    localStorage.un = '';
    localStorage.uid = '';
    localStorage.hash = '';
    localStorage.pin = '';
    localStorage.isHasPin = false;
    commitSetUser(store, {
        ref: '',
        un: '',
        hash: '',
        pin: 0,
        uid: -1,
        isHasPin: false,
    });
    window.location.href = '/';
  }

  return result;
}
