<template>
  <div class="menu navbar__menu">
    <router-link class="menu__link" :key="`topNav-${index}`"
       v-for="(item, index) in data" :to="item.url">
      {{ $t(item.name) }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'TopNav',
  props: ['data'],
}
</script>

<style scoped>

</style>
